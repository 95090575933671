import "./ContactCard.scss";
import { MdEmail } from 'react-icons/md';
import { FaLinkedin } from 'react-icons/fa';

function ContactCard(props) {
  return (
    <div className="ContactCard" data-testid="ContactCard">
      <img className="ContactCard-image" alt={props.imgAltText} src={props.imgSrc}></img>
      <h1 className="ContactCard-name">{props.contactName}</h1>
      <div className="ContactCard-info">
        <span className="ContactCard-email"><MdEmail/> {props.email}</span>
        <span><FaLinkedin /> <a className="ContactCard-linkedin" href={props.linkedin}>LinkedIn Profile</a></span>
      </div>
    </div>
  );
}

export default ContactCard;

import "./SkillCard.scss";

function getProficiencyClassName(proficiency) {
  switch (proficiency) {
    case "Proficient":
      return "SkillCard-proficiency-proficient";
    case "Basic":
      return "SkillCard-proficiency-basic";
    case "Familiar":
      return "SkillCard-proficiency-familiar";
    default:
      return "";
  }
}

function SkillCard(props) {
  let additionalSkills = [];
  if (props.additionalSkills) {
    additionalSkills = props.additionalSkills.map((additionalSkill) => {
      return (
        <div className="SkillCard-additional-skill" key={additionalSkill.name}>
          <span className="SkillCard-additional-skill-name">{additionalSkill.name}</span>
          <span className={`SkillCard-proficiency ${getProficiencyClassName(additionalSkill.proficiency)}`}>{additionalSkill.proficiency}</span>
        </div>
      )
    })
  }

  return (
    <div className="SkillCard" data-testid={`SkillCard-${props.skillName}`}>
      <div className="SkillCard-header">
        <span className="SkillCard-name">{props.skillName}</span>
        <span className={`SkillCard-proficiency ${getProficiencyClassName(props.proficiency)}`}>{props.proficiency}</span>
      </div>
      <div className="SkillCard-additional-skills">
        {additionalSkills}
      </div>
    </div>
  )
}

export default SkillCard;

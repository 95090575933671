import { Component } from "react";
import { v1, v4 } from "uuid";
import "./UuidGenerator.scss";

class UuidGenerator extends Component {
  constructor() {
    super();

    this.state = {
      currentUuid1: "",
      currentUuid4: ""
    }

    this.generateUuidV1 = this.generateUuidV1.bind(this);
    this.generateUuidV4 = this.generateUuidV4.bind(this);
  }
  
  generateUuidV1() {
    this.setState({ currentUuid1: v1() });
  }

  generateUuidV4() {
    this.setState({ currentUuid4: v4() });
  }

  render() {
    return (
      <div className="UuidGenerator" data-testid="UuidGenerator">
        <div className="UuidGenerator-container">
          <div className="UuidGenerator-button noselect" data-testid="UuidGenerator-button-generate-uuid-1" onClick={this.generateUuidV1}>Generate UUID v1</div>
          <div className="UuidGenerator-output"><span data-testid="UuidGenerator-output-uuid-1">{this.state.currentUuid1}</span></div>
        </div>
        <div className="UuidGenerator-container">
          <div className="UuidGenerator-button noselect" data-testid="UuidGenerator-button-generate-uuid-4" onClick={this.generateUuidV4}>Generate UUID v4</div>
          <div className="UuidGenerator-output"><span data-testid="UuidGenerator-output-uuid-4">{this.state.currentUuid4}</span></div>
        </div>
      </div>
    )
  }
}

export default UuidGenerator;

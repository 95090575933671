import "./PersonalProjects.scss";
import NavBar from "../../components/NavBar/NavBar";
import Project from "../../components/Project/Project";
import projects from "../../data/Projects";

function PersonalProjects(props) {
  return (
    <div className="PersonalProjects" data-testid="PersonalProjects">
      <NavBar active="personal-projects" />
      <div className="PersonalProjects-projects">
        <h1>Personal Projects</h1>
        {projects.map((project) => {
          return (
            <Project 
              key={project.name}
              projectName={project.name}
              projectThumbnail={project.thumbnail}
              thumbnailAltText={project.thumbnailAltText}
              skillsUsed={project.skillsUsed}
              description={project.description}
              projectLink={project.projectLink}/>
          )
        })}
      </div>
    </div>
  )
}

export default PersonalProjects;

import { Component } from "react";
import "./JsonPrettyPrint.scss";

class JsonPrettyPrint extends Component {
  constructor() {
    super();

    this.state = {
      inputValue: "",
      outputValue: ""
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.prettyPrint = this.prettyPrint.bind(this);
  }

  handleInputChange(e) {
    this.setState({ inputValue: e.target.value });
  }

  prettyPrint() {
    try {
      let jsonObject = JSON.parse(this.state.inputValue);
      this.setState({ outputValue: JSON.stringify(jsonObject, undefined, 2)});
    }
    catch(e) {
      this.setState({ outputValue: "Invalid JSON" });
    }
  }

  render() {
    return (
      <div className="JsonPrettyPrint" data-testid="JsonPrettyPrint">
        <textarea className="JsonPrettyPrint-data-entry" data-testid="JsonPrettyPrint-input" value={this.state.inputValue} onChange={this.handleInputChange}></textarea>
        <div className="JsonPrettyPrint-button-container">
          <div className="JsonPrettyPrint-button noselect" data-testid="JsonPrettyPrint-button-pretty-print" onClick={this.prettyPrint}>Pretty Print</div>
        </div>
        <textarea className="JsonPrettyPrint-output" data-testid="JsonPrettyPrint-output" readOnly={true} value={this.state.outputValue}></textarea>
      </div>
    )
  }
}

export default JsonPrettyPrint;

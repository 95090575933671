import "./Utils.scss";

import { Component } from "react";
import NavBar from "../../components/NavBar/NavBar";
import Base64Encoder from "../../components/Base64Encoder/Base64Encoder";
import URLEncoder from "../../components/URLEncoder/URLEncoder";
import JsonPrettyPrint from "../../components/JsonPrettyPrint/JsonPrettyPrint";
import UuidGenerator from "../../components/UuidGenerator/UuidGenerator";

class Utils extends Component {
  constructor() {
    super();

    this.state = {
      currentTool: ""
    }

    this.changeTool = this.changeTool.bind(this);
  }

  changeTool(e) {
    this.setState({ currentTool: e.target.value });
  }

  render() {
    let currentTool;
    switch (this.state.currentTool) {
      case "base64":
        currentTool = <Base64Encoder />
        break;
      case "urlencode":
        currentTool = <URLEncoder />
        break;
      case "jsonpp":
        currentTool = <JsonPrettyPrint />
        break;
      case "uuidgen":
        currentTool = <UuidGenerator />
        break;
      default:
        currentTool = <div></div>
        break;
    }

    return (
      <div className="Utils" data-testid="Utils">
        <NavBar active="utils" />
        <div className="Utils-main">
          <div className="Utils-tool-select-container">
            <b>Tool: </b>
            <select className="Utils-tool-select" data-testid="Utils-tool-select" onChange={this.changeTool}>
              <option value=""></option>
              <option value="base64">Base64 Encode/Decode</option>
              <option value="urlencode">URL Encode/Decode</option>
              <option value="jsonpp">JSON Pretty Print</option>
              <option value="uuidgen">UUID Generator</option>
            </select>
          </div>
          <div className="Utils-tool-container">
            {currentTool}
          </div>
        </div>
      </div>
    )
  }
}

export default Utils;

import './Home.scss';
import ContactCard from '../../components/ContactCard/ContactCard';
import AboutMe from '../../components/AboutMe/AboutMe';
import Skills from '../../components/Skills/Skills';
import skillList from '../../data/SkillList'
import NavBar from '../../components/NavBar/NavBar';

function Home() {
  return (
    <div className="Home" data-testid="Home">
      <NavBar active="home" />
      <header className="Home-header">
        <ContactCard
          imgSrc="/william-wallace.jpg"
          imgAltText="Picture of William Wallace"
          contactName="William Wallace"
          email="willtwall@gmail.com"
          linkedin="https://www.linkedin.com/in/william-w-0ab5a086/"/>
        <AboutMe>
          I'm a full-stack Web Developer who loves learning. I'm known for finding practical solutions to complex problems and learning new technologies quickly. I have significant experience in <b>Java, Node, AWS, JavaScript/TypeScript,</b> and more.
        </AboutMe>
        <Skills skillList={skillList}/>
      </header>
    </div>
  );
}

export default Home;

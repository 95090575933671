import { IconContext } from "react-icons";
import "./NavBar.scss";
import { FiMenu } from "react-icons/fi";
import { ImCross } from "react-icons/im";
import { Component } from "react";

class NavBar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isCollapsed: true
    }

    this.toggleCollapsedState = this.toggleCollapsedState.bind(this);
  }

  toggleCollapsedState() {
    this.setState({ isCollapsed: !this.state.isCollapsed })
  }

  render() {
    return (
      <div className="NavBar" data-testid="NavBar">
        <div className="NavBar-desktop-links">
          <a className={`NavBar-link ${this.props.active === "home" ? "active" : ""}`} href="/">Home</a>
          <span className="NavBar-separator"></span>
          <a className={`NavBar-link ${this.props.active === "personal-projects" ? "active" : ""}`} href="/personal-projects">Personal Projects</a>
          <span className="NavBar-separator"></span>
          <a className={`NavBar-link ${this.props.active === "utils" ? "active" : ""}`} href="/utils">Utilities</a>
          <span className="NavBar-separator"></span>
        </div>
        <div className={`NavBar-collapsible-links ${this.state.isCollapsed ? "collapsed" : ""}`}>
          <a className={`NavBar-link ${this.props.active === "home" ? "active" : ""}`} href="/">Home</a>
          <a className={`NavBar-link ${this.props.active === "personal-projects" ? "active" : ""}`} href="/personal-projects">Personal Projects</a>
          <a className={`NavBar-link ${this.props.active === "utils" ? "active" : ""}`} href="/utils">Utilities</a>
        </div>
        <div className="NavBar-menu-button-container">
          <span className="NavBar-menu-button" onClick={this.toggleCollapsedState}>
            <IconContext.Provider value={{ color: "white", size: "30px" }}>
              {this.state.isCollapsed ? <FiMenu /> : <ImCross />}
            </IconContext.Provider>
          </span>
        </div>
      </div>
    )
  }
}

export default NavBar;

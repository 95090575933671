import "./AboutMe.scss";

function AboutMe(props) {
  return (
    <div className="Summary">
      <h2 className="AboutMe-title">About Me</h2>
      <div className="AboutMe-info">
        {props.children}
      </div>
    </div>
  )
}

export default AboutMe;

import "./Skills.scss";
import SkillCard from "./SkillCard";

function createSkillCardsFor(skillList) {
  return skillList.map((skill) => {
    return (
      <SkillCard
        key={skill.name}
        skillName={skill.name}
        proficiency={skill.proficiency}
        additionalSkills={skill.additionalSkills}/>
    )
  })
}

function Skills(props) {
  const languageList = createSkillCardsFor(props.skillList.languages);
  const toolList = createSkillCardsFor(props.skillList.tools);
  const databaseList = createSkillCardsFor(props.skillList.databases);
  const methodologyList = createSkillCardsFor(props.skillList.methodologies);

  return (
    <div className="Skills" data-testid="Skills">
      <h2 className="Skills-title">Skills</h2>
      <h3 className="Skills-section-title">Languages</h3>
      <div className="Skills-list Skills-languages">
        {languageList}
      </div>
      <h3 className="Skills-section-title">Tools</h3>
      <div className="Skills-list Skills-tools">
        {toolList}
      </div>
      <h3 className="Skills-section-title">Databases</h3>
      <div className="Skills-list Skills-databases">
        {databaseList}
      </div>
      <h3 className="Skills-section-title">Methodologies</h3>
      <div className="Skills-list Skills-methodologies">
        {methodologyList}
      </div>
    </div>
  )
}

export default Skills;

import "./Project.scss";

function Project(props) {
  const skillsUsed = props.skillsUsed.join(", ");
  
  let projectLink = "";
  if (props.projectLink !== "") {
    projectLink = <a href={props.projectLink}>See Project</a>
  }

  return (
    <div className="Project" data-testid={`Project-${props.projectName}`}>
      <h2 className="Project-name">{props.projectName}</h2>
      <div className="Project-container">
        <div>
          <img className="Project-thumbnail" src={props.projectThumbnail} alt={props.thumbnailAltText}/>
        </div>
        <div className="Project-content">
          <div className="Project-skills-used"><b>Skills Used: </b><i>{skillsUsed}</i></div>
          <div className="Project-description">{props.description}</div>
          <div className="Project-link">
            {projectLink}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Project;
const skillList = {
  languages: [
    {
      name: "Java",
      proficiency: "Proficient",
      additionalSkills: [
        {
          name: "Springboot",
          proficiency: "Proficient"
        },
        {
          name: "JUnit",
          proficiency: "Proficient"
        },
        {
          name: "Checkstyle",
          proficiency: "Basic"
        },
        {
          name: "PMD",
          proficiency: "Familiar"
        },
        {
          name: "ArchUnit",
          proficiency: "Familiar"
        }
      ]
    },
    {
      name: "JavaScript & TypeScript",
      proficiency: "Proficient",
      additionalSkills: [
        {
          name: "Angular",
          proficiency: "Proficient"
        },
        {
          name: "ESLint",
          proficiency: "Proficient"
        },
        {
          name: "React",
          proficiency: "Basic"
        },
        {
          name: "Vue",
          proficiency: "Basic"
        },
        {
          name: "Express",
          proficiency: "Basic"
        },
        {
          name: "Karma",
          proficiency: "Basic"
        },
        {
          name: "Jest",
          proficiency: "Basic"
        },
        {
          name: "Mocha",
          proficiency: "Familiar"
        }
      ]
    },
    {
      name: "HTML/CSS",
      proficiency: "Proficient",
      additionalSkills: [
        {
          name: "SCSS",
          proficiency: "Basic"
        }
      ]
    },
    {
      name: "C",
      proficiency: "Proficient"
    },
    {
      name: "C++",
      proficiency: "Proficient"
    },
    {
      name: "Python",
      proficiency: "Basic",
      additionalSkills: [
        {
          name: "NumPy",
          proficiency: "Basic"
        },
        {
          name: "Jupyter",
          proficiency: "Basic"
        }
      ]
    },
    {
      name: "Kotlin",
      proficiency: "Basic"
    },
    {
      name: "C#",
      proficiency: "Basic"
    },
    {
      name: "PHP",
      proficiency: "Basic"
    },
    {
      name: "Rust",
      proficiency: "Familiar"
    },
    {
      name: "Go",
      proficiency: "Familiar"
    }
  ],
  tools: [
    {
      name: "Node",
      proficiency: "Proficient",
      additionalSkills: [
        {
          name: "NPM",
          proficiency: "Proficient"
        }
      ]
    },
    {
      name: "Maven",
      proficiency: "Proficient"
    },
    {
      name: "AWS",
      proficiency: "Proficient",
      additionalSkills: [
        {
          name: "ECS",
          proficiency: "Proficient"
        },
        {
          name: "Lambda",
          proficiency: "Proficient"
        },
        {
          name: "IAM",
          proficiency: "Proficient"
        },
        {
          name: "RDS",
          proficiency: "Basic"
        },
        {
          name: "DynamoDB",
          proficiency: "Basic"
        }
      ]
    },
    {
      name: "Docker",
      proficiency: "Proficient",
      additionalSkills: [
        {
          name: "Docker Compose",
          proficiency: "Proficient"
        }
      ]
    },
    {
      name: "Terraform",
      proficiency: "Proficient"
    },
    {
      name: "Git",
      proficiency: "Proficient"
    },
    {
      name: "GitHub",
      proficiency: "Proficient",
      additionalSkills: [
        {
          name: "GitHub Actions",
          proficiency: "Basic"
        }
      ]
    },
    {
      name: "GitLab",
      proficiency: "Proficient",
      additionalSkills: [
        {
          name: "GitLab CI/CD",
          proficiency: "Basic"
        }
      ]
    },
    {
      name: "Bitbucket",
      proficiency: "Proficient",
      additionalSkills: [
        {
          name: "Bitbucket Pipelines",
          proficiency: "Proficient"
        }
      ]
    },
    {
      name: "Kubernetes",
      proficiency: "Basic"
    },
    {
      name: "Jenkins",
      proficiency: "Basic"
    },
    {
      name: "Gradle",
      proficiency: "Basic"
    },
    {
      name: "Mendix",
      proficiency: "Basic"
    },
    {
      name: "Drupal",
      proficiency: "Basic"
    },
    {
      name: "Wordpress",
      proficiency: "Familiar"
    }
  ],
  databases: [
    {
      name: "MySQL",
      proficiency: "Basic"
    },
    {
      name: "PostgreSQL",
      proficiency: "Basic"
    }
  ],
  methodologies: [
    {
      name: "DevOps",
      proficiency: "Proficient"
    },
    {
      name: "Test-Driven Development",
      proficiency: "Proficient"
    },
    {
      name: "Agile",
      proficiency: "Proficient"
    },
    {
      name: "Domain-Driven Design",
      proficiency: "Basic"
    },
    {
      name: "Behavior-Driven Development",
      proficiency: "Basic"
    },
    {
      name: "Kanban",
      proficiency: "Basic"
    }
  ]
}

export default skillList;
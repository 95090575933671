import "./URLEncoder.scss";

import { Component } from "react";

class URLEncoder extends Component {
  constructor() {
    super();

    this.state = {
      inputValue: "",
      outputValue: ""
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.urlEncode = this.urlEncode.bind(this);
    this.urlDecode = this.urlDecode.bind(this);
  }

  handleInputChange(e) {
    this.setState({ inputValue: e.target.value });
  }

  urlEncode() {
    try {
      this.setState({ outputValue: encodeURIComponent(this.state.inputValue) });
    }
    catch(e) {
      this.setState({ outputValue: "Unexpected error when encoding to URL encoded format" });
    }
  }

  urlDecode() {
    try {
      this.setState({ outputValue: decodeURIComponent(this.state.inputValue) });
    }
    catch(e) {
      this.setState({ outputValue: "Invalid URL encoded string" });
    }
  }

  render() {
    return (
      <div className="URLEncoder" data-testid="URLEncoder">
        <textarea className="URLEncoder-data-entry" data-testid="URLEncoder-input" value={this.state.inputValue} onChange={this.handleInputChange}></textarea>
        <div className="URLEncoder-button-container">
          <div className="URLEncoder-button noselect" data-testid="URLEncoder-button-encode" onClick={this.urlEncode}>Encode</div>
          <div className="URLEncoder-button noselect" data-testid="URLEncoder-button-decode" onClick={this.urlDecode}>Decode</div>
        </div>
        <textarea className="URLEncoder-output" data-testid="URLEncoder-output" readOnly={true} value={this.state.outputValue}></textarea>
      </div>
    )
  }
}

export default URLEncoder;

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.scss';
import Home from "./pages/Home/Home";
import reportWebVitals from './reportWebVitals';
import Utils from './pages/Utils/Utils';
import PersonalProjects from './pages/PersonalProjects/PersonalProjects';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route index element={<Home/>} />
      <Route path="personal-projects" element={<PersonalProjects />} />
      <Route path="utils" element={<Utils />} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

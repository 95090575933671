import "./Base64Encoder.scss";

import { Component } from "react";

class Base64Encoder extends Component {
  constructor() {
    super();

    this.state = {
      inputValue: "",
      outputValue: ""
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.base64Encode = this.base64Encode.bind(this);
    this.base64Decode = this.base64Decode.bind(this);
  }

  handleInputChange(e) {
    this.setState({ inputValue: e.target.value });
  }

  base64Encode() {
    try {
      this.setState({ outputValue: btoa(this.state.inputValue) });
    }
    catch(e) {
      this.setState({ outputValue: "Unexpected error when encoding to base 64"})
    }
  }

  base64Decode() {
    try {
      this.setState({ outputValue: atob(this.state.inputValue) });
    }
    catch(e) {
      this.setState({ outputValue: "Invalid base 64 string" });
    }
  }

  render() {
    return (
      <div className="Base64Encoder" data-testid="Base64Encoder">
        <textarea className="Base64Encoder-data-entry" data-testid="Base64Encoder-input" value={this.state.inputValue} onChange={this.handleInputChange}></textarea>
        <div className="Base64Encoder-button-container">
          <div className="Base64Encoder-button noselect" data-testid="Base64Encoder-button-encode" onClick={this.base64Encode}>Encode</div>
          <div className="Base64Encoder-button noselect" data-testid="Base64Encoder-button-decode" onClick={this.base64Decode}>Decode</div>
        </div>
        <textarea className="Base64Encoder-output" data-testid="Base64Encoder-output" readOnly={true} value={this.state.outputValue}></textarea>
      </div>
    )
  }
}

export default Base64Encoder;

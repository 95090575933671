const projects = [
  {
    name: "Sedensura (Demo)",
    thumbnail: "thumbnails/sedensura-thumbnail.png",
    thumbnailAltText: "Picture of Jack from Sedensura",
    skillsUsed: [
      "C#",
      "Unity",
      "Ableton Live",
      "Pixel Art",
      "Sound Design"
    ],
    description: "A simple 2D platformer I made in Unity. I learned a lot about useful design patterns, and the creative process in general. This project is in a released state and currently gathering interest. I'll make it into a full game if it's popular enough.",
    projectLink: "https://mastersanjai.itch.io/sedensura"
  },
  {
    name: "Portfolio Page",
    thumbnail: "thumbnails/react-logo.png",
    thumbnailAltText: "React Logo",
    skillsUsed: [
      "React",
      "Jest",
      "AWS",
      "GitLab CI/CD"
    ],
    description: "This is my portfolio page that you're looking at right now. I'm using this to showcase my work and skills. I also added a few tools to this webpage like a Base64 encoder/decoder. These are some basic tools that I find I use a lot when coding.",
    projectLink: ""
  },
  {
    name: "BMP Renderer",
    thumbnail: "thumbnails/bmp-renderer-sphere.bmp",
    thumbnailAltText: "Picture of a sphere rendered using BMP renderer",
    skillsUsed: [
      "C++",
      "Linear Algebra"
    ],
    description: "A simple 3D rendering engine that takes polygons in a scene and renders them into a BMP image. This is mostly a project to practice my C++.",
    projectLink: "https://gitlab.com/masterqwerty/bmp-renderer"
  }
]

export default projects;
